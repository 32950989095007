// eslint-disable-next-line import/prefer-default-export
export const arizonaHero = {
  title: "Point-of-sale is just the beginning",
  subtext:
    "As an association member, you can choose how you want to save with SpotOn. Either get a credit towards your first POS station or receive a free consultation, set up, and trial of SpotOn Reserve or SpotОn's labor management solution. Click to schedule a demo to learn more.",
  heroImg: "Arizona.png",
  imageClassName: "fixed-height-700 top-adjustement-b",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/associations/demo",
  },
  video: {
    videoBtn: "Play video",
    posterWrapperClass: "",
    posterImg: "hero-video-image.png",
    videoUrlId: "hLlIUkFESiw",
  },
};

export const arizonaSeal = {
  title: "Arizona Restaurant Association:",
  img: "ARA_logo_without-preferred.png",
  text: "SpotOn is an established Technology Partner of the Arizona Restaurant Association",
  // url: "/blog/preferred-technology-partner-arizona-restaurant-association/",
};

export const arizonaLargeFeatures = {
  featureBlocks: [
    {
      blockTitle: "Online ordering, delivery & dine-in ordering",
      blockImg: "large-phone.png",
      blockSubTitle:
        "Say goodbye to third-party apps. With SpotOn's digital solutions for takeout, delivery, and dining in, you can not only increase your throughput and increase your revenue but also save money.",
      blockList: [
        "Guests order directly from you—no app needed",
        "QR dine-in ordering to speed up service",
        "SpotOn Delivery, powered by DoorDash",
        "Order with Google integration to reach more guests",
        "Full POS integration with pacing controls",
        "Own your guest data",
      ],
      id: "oo",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Reservations & waitlisting",
      blockSubTitle:
        "Ensure your guests a table will be waiting for them and streamline your front-of-house operations, and save big with no per-cover fees like other reservation platforms.\n",
      blockImg: "reservation_confirmed.png",
      blockList: [
        "24-hour online booking",
        "Waitlisting & real-time notifications",
        "Server & table management",
        "Reserve with Google listing",
        "Optional vaccination status checkbox",
        "POS integration or standalone front-of-house",
      ],
      id: "rct",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Contactless payments & ordering",
      blockImg: "contactless-payments.png",
      blockSubTitle:
        "Give your guests what they want. With more ways to order and pay, you'll reduce person-to-person contact, and your staff will be able to serve more guests quicker.\n",
      blockList: [
        "Handheld POS",
        "QR code on receipt",
        "Credit card terminal",
        "Customer-facing displays",
        "Mobile dine-in ordering",
        "Mobile card readers",
      ],
      id: "cp",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
    {
      blockTitle: "Built-in marketing & loyalty",
      blockImg: "Marketing.png",
      blockSubTitle:
        "Connect with your customers online, in-person, and on their mobile phones to grow your business. SpotOn's built-in tools are designed to make it easy to grow your mailing list, attract new customers, and keep your regulars coming back.",
      blockList: [
        "Email marketing & social campaigns",
        "Loyalty rewards",
        "Online review management",
        "Online dashboard",
        "Mobile dashboard app",
        "Guest and campaign data & insights",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/associations/demo",
      },
    },
  ],
};
export const arizonaRestaurantTypes = [
  {
    content: "Casual Full-Service",
    desc: "Boost throughput and create a top-notch experience that lets guests order and pay their way.",
    img: "casual_fs.png",
  },
  {
    content: "Bars & Nighclubs",
    desc: "Streamline operations and monitor bar stations remotely with top-shelf tech.",
    img: "bars_clubs.png",
  },
  {
    content: "Quick Service",
    desc: "Accelerate the checkout process with integrated discount and loyalty rewards to help drive repeat visits.",
    img: "quick-serve.png",
  },
  {
    content: "Enterprise",
    desc: "Manage menus and labor across multiple location, with real-time customer insights and data.",
    img: "enterprise.png",
  },
  {
    content: "Pizzeria",
    desc: "Simplify operations, including delivery, while giving your customers multiple ways to order and pay.",
    img: "pizzeria.png",
  },
  {
    content: "Fine Dining",
    desc: "Create a VIP-level experience and boost efficiency with integrated reservations, waitlisting, and point-of-sale.",
    img: "fine-dine.png",
  },
];

export const arizonaTestimonials = {
  title: "What SpotOn customers in arizona are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const arizonaIconTemplate = [
  {
    content: "Front-of-house",
    desc: "Create an unforgettable guest experience & streamline operations",
    img: "book-open.png",
  },
  {
    content: "Back-of-house",
    desc: "Update menus, pricing specials, and team members in real-time",
    img: "urgent.png",
  },
  {
    content: "Handheld POS & mobile ordering",
    desc: "Cut the cord to serve guests better and turn tables faster",
    img: "calc.png",
  },
  {
    content: "Elite guest data & reporting",
    desc: "Get the insights you need to adapt quickly on any device, including your phone",
    img: "pie-chart.png",
  },
  {
    content: "Third-party integrations",
    desc: "Connect your POS to the other software you love",
    img: "git-merge.png",
  },
  {
    content: "In-person installation & training",
    desc: "Get your staff started right with help from real-life human experts",
    img: "award.png",
  },
  {
    content: "Online ordering",
    desc: "If you’re looking for quick, easy order and pay options that are a cut above your competitors, SponOn’s point-of-sale solutions can deliver all that plus loyalty programs that work",
    img: "online-purchase.png",
  },
  {
    content: "Reservations",
    desc: "Easily manage your waitlist and reservation system in real time with the SpotOn Reserve front-of-house solution",
    img: "reservation.png",
  },
];

export const arizonaAutoIconTemplate = [
  {
    content: "SpotOn Terminal",
    desc: "Cut the wires, connect with customers, and get paid. Take payments wherever you want, offer cash discounts, print receipts, and drive repeat visits with built-in digital loyalty",
    img: "virtual-terminal.png",
  },
  {
    content: "Online Appointments",
    desc: "Easily manage appointments and team member schedules while allowing your customers to book at any time, right from your website or your Facebook page",
    img: "appointment.png",
  },
  {
    content: "Custom Web Design",
    desc: "Our specialists will build a professional website for you at a do-it-yourself price, so you show up higher in Google searches and attract more customers",
    img: "website.png",
  },
  {
    content: "Integrated marketing tools",
    desc: "Run and grow your business from a single dashboard—online or right from your phone. Manage your payments, loyalty rewards, and appointments, send marketing emails, monitor your online reputation on Yelp and Google, and send invoices",
    img: "marketing.png",
  },
];

export const arizonaXL = {
  mainTitle: "Proudly serving businesses across Arizona",
  subtext:
    "Unlike other companies that sell you a product and then disappear, SpotOn has a dedicated team of local professionals to help you future-proof your business and who will continue to be with you with every new challenge that comes your way. They are trusted members of our Arizona business communities, and will always put your business first.",
  phoneNumber: "+8142067706",
  phoneText: "(814) 206-7706",
  email: "danielcabanillas@spoton.com",
};

export const arizonaWhiteGlove = {
  mainTitle: "Industry-leading service & support",
  title: "We’re here. Wherever you need us",
  content:
    "You and your business deserve the best professional support. This is why we provide local service with onsite, in-person training, and 24/7/365 technical support. ",
  contentList: [
    "Face-to-face hardware installation",
    "In-person staff training",
    "Virtual training whenever you need it",
  ],
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/associations/demo",
  },
};
