import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import {
  arizonaHero,
  arizonaRestaurantTypes,
  arizonaIconTemplate,
  arizonaLargeFeatures,
  arizonaWhiteGlove,
  arizonaXL,
  arizonaSeal,
} from "../../data/local/arizona-data";
import ogImage from "../../images/global_assets/og-image.png";
import LocalHero from "../../components/Hero/LocalHero";
import { georgiaTestimonialsData } from "../../data/local/georgia-data";
import LocalSlider from "../../components/ColoradoPortedComps/LocalSlider";
import PartnerLogos from "../../components-v2/Sections/PartnerLogos";

const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LocalRestaurants = loadable(() =>
  import("../../components/ColoradoPortedComps/LocalRest")
);
const Restaurants = loadable(() =>
  import("../../components/ColoradoPortedComps/Rest")
);
const XLArea = loadable(() =>
  import("../../components/ColoradoPortedComps/XLArea")
);
// const TestmonialReviews = loadable(() =>
//   import("../../components/TestimonialReviews/TestmonialReviews")
// );
const whiteGloveImport = "caregiver.png";

const Arizona = () => {
  return (
    <Layout
      isLanding
      secondaryNav
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="SpotOn | Arizona | Point-of-Sale"
        description="Arizona's restaurants and businesses deserve a top-rated point-of-sale system and software to run and grow their business. SpotOn is here to help with industry-leading technology, face-to-face local support, and fair pricing."
        image={`https://spoton.com/${ogImage}`}
      />
      <LocalHero sectionData={arizonaHero} seal={arizonaSeal} />
      <PartnerLogos />
      <LocalRestaurants
        data={arizonaRestaurantTypes}
        title="Proudly serving all types of restaurants in Arizona"
        nav="#"
      />
      <Restaurants
        data={arizonaIconTemplate}
        nav="pos"
        title="SpotOn Restaurant POS"
        subtext="Get the industry-leading restaurant POS built around your needs, with cloud-based tools that help you save you time, increase your sales, get better data, and maximize your staff."
      />
      <LocalSlider
        nav="cust"
        title="What other restaurants have to say"
        testimonialsData={georgiaTestimonialsData}
      />
      <LargeFeatures
        sectionData={arizonaLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <XLArea data={arizonaXL} nav="service" />
      <WhiteGlove
        sectionData={arizonaWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
    </Layout>
  );
};

export default Arizona;
