import React, { useState } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import YouTube from "react-youtube";
import WhyHeroBg from "../_NewAnimated/NewWhyHero/WhyHeroBg";
import Image from "../ImageQuerys/PosterImages";
import LocalHeroStyles from "../ColoradoPortedComps/LocalHeroStyles";
import CtaPrimary from "../Buttons/primary-cta";
import playIcon from "../../images/svg/play.svg";
import RASeal from "../ColoradoPortedComps/RASeal";

const LocalHero = (props) => {
  const { nav, sectionData, seal } = props;
  const [isPlaying, setIsPlaying] = useState(false);
  function playVideo() {
    setIsPlaying(true);
  }
  const playerOpts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      contorls: 1,
      modestbranding: 1,
    },
  };

  function onPlayerReady(event) {
    event.target.playVideo();
  }
  return (
    <section>
      <div
        style={{
          opacity: 0.35,
        }}
      >
        <WhyHeroBg
          imageName={sectionData.heroImg}
          style={{
            width: "100%",
            height: "94%",
            position: "absolute",
            maxWidth: 1440,
            maxHeight: 690,
            margin: "auto",
            opacity: 0.35,
          }}
        />
      </div>
      <div className="local-hero" id={nav}>
        <Row gutter={[16, 16]} justify="center">
          <Col sm={24} md={24} lg={12} xl={12} xs={24}>
            <h1 className="local-title">
              {sectionData.title}
              <span style={{ color: "#1769FF" }}>.</span>
            </h1>
            {sectionData.subtext && (
              <p className="max-w-[400px] text-black-300 text-[20px]">
                {sectionData.subtext}
              </p>
            )}
            <CtaPrimary
              ctaTitle={sectionData.PrimaryCta.ctaTitle}
              target={sectionData.PrimaryCta.ctaTarget}
            />
          </Col>
          {!isPlaying && (
            <Col sm={24} md={24} lg={12} xl={12} xs={24}>
              <div className="video-placeholder">
                <Image
                  imageName={sectionData.video.posterImg}
                  style={{
                    width: "100%",
                    height: "100%",
                    maxHeight: 330,
                    borderRadius: 8,
                  }}
                />
                {/* <img src={vid} alt="" /> */}
                <div
                  className="video-controls"
                  onClick={playVideo}
                  onKeyPress={playVideo}
                  role="button"
                  tabIndex="0"
                >
                  <img src={playIcon} className="video-play" alt="" />
                  <p className="light">Play Video</p>
                </div>
              </div>
            </Col>
          )}
          {isPlaying && (
            <Col sm={24} md={24} lg={12} xl={12} xs={24}>
              <div style={{ borderRadius: 12 }}>
                <YouTube
                  videoId={sectionData.video.videoUrlId}
                  opts={playerOpts}
                  className="video__player"
                  /* eslint-disable-next-line react/jsx-no-bind */
                  onReady={onPlayerReady}
                />
              </div>
            </Col>
          )}
        </Row>
        {seal && <RASeal seal={seal} />}
        <LocalHeroStyles />
      </div>
    </section>
  );
};

LocalHero.propTypes = {
  nav: PropTypes.string,
  sectionData: PropTypes.objectOf(PropTypes.any),
  seal: PropTypes.objectOf(PropTypes.any),
};
LocalHero.defaultProps = {
  nav: "",
  sectionData: "",
  seal: "",
};

export default LocalHero;
