import React from "react";
import { viewports, colors } from "../../style-vars";

const { xsOnly, smOrSmaller, mdOrSmaller } = viewports;
const { white, black } = colors;

const LocalHeroStyles = ({}) => (
  <style jsx global>{`
    .local-hero {
      position: relative;
      width: 100%;
      overflow: hidden;
      min-height: 737px;
      margin-top: 0 !important;
      padding: 80px 126px 0 126px;
    }
    .local-title {
      font-weight: 800;
      font-size: 48px;
      line-height: 1.4;
      color: #090d12;
    }
    .video-placeholder {
      position: relative;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.13);
      background-color: transparent;
      border-radius: 8px;
      width: 100%;
      max-height: 330px;
    }
    .video-placeholder:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      content: "";
      background: rgba(23, 105, 255, 0.1);
    }
    .video-controls {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .video-play {
      color: #fff;
      margin-bottom: 12px;
      width: 40px;
    }
    .video-controls:hover {
      cursor: pointer;
    }
    .video-controls p {
      font-size: 24px;
      font-family: sofia-pro, sans-serif;
      font-weight: 700;
      color: #fff;
    }
    .video__player {
      height: 330px;
      border-radius: 8px;
      box-shadow: 0px 0px 94px -42px rgba(23, 105, 255, 1);
    }
    @media ${smOrSmaller} {
      .local-hero {
        position: relative;
        width: 100%;
        overflow: hidden;
        min-height: 737px;
        margin-top: 0 !important;
        padding: 32px 16px;
      }
      .local-title {
        font-weight: 800;
        font-size: 32px;
        line-height: 1.7;
        color: #090d12;
      }
    }
  `}</style>
);

export default LocalHeroStyles;
