import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import Image from "../ImageQuerys/LocalImages";
import bg from "../../images/local-images/background_banner.png";

const RASeal = (props) => {
  const { seal } = props;

  return (
    <Row className="blog_highlight" style={{ backgroundImage: `url(${bg})` }}>
      <Col className="blog_forward" lg={16}>
        <Image className="video-seal-banner" imageName={seal.img} />
        <p style={{ color: "black" }}>
          <span style={{ fontWeight: "bold" }}>{seal.title}</span> <br />{" "}
          {seal.text}
        </p>
      </Col>
      {!!seal.withCta && (
        <Col className="blog_btn" lg={6}>
          <a href={seal.url} rel="noopener" target="blank">
            <p className="btn-article">Read More</p>
          </a>
        </Col>
      )}

      <style jsx global>{`
        .blog_highlight {
          width: 85%;
          height: 100%;
          position: relative;
          z-index: 3;
          box-shadow: 0px 0px 13px 0px #9e9c9e;
          border-radius: 8px;
          display: table;
          margin: 10px auto 60px auto;
          background-position: center;
        }
        @media screen and (min-width: 992px) {
          .blog_highlight {
            height: 100px;
            margin-top: 6%;
          }
        }
        .video-seal-banner {
          width: 170px;
          position: absolute !important;
          left: -25px;
          top: -40px;
          box-shadow: 0px 0px 13px 0px #9e9c9e;
          border-radius: 50%;
          background-color: #fff;
        }
        @media screen and (max-width: 992px) {
          .blog_highlight {
            height: 100px;
            margin-top: 120px;
          }
          .video-seal-banner {
            display: none;
          }
        }
        .blog_forward {
          color: white;
          font-size: 16px;
          position: relative;
          top: 0%;
          width: 90%;
          padding-left: 0;
          padding-top: 10px;
          margin: auto;
        }
        @media screen and (min-width: 992px) {
          .blog_forward {
            width: 80%;
            padding-left: 20%;
            position: absolute;
            top: 0%;
          }
        }
        .blog_btn {
          position: absolute;
          top: 30%;
          width: 100%;
          right: 1%;
          margin-left: 5%;
        }
        @media screen and (max-width: 992px) {
          .blog_btn {
            margin-left: 5%;
            bottom: auto;
            right: 1%;
            position: relative;
          }
        }
        .btn-article {
          border-radius: 100px;
          border: none !important;
          font-weight: 700 !important;
          font-size: 18px;
          padding: 5px 32px !important;
          display: inline-block;
          background: white;
          color: #092a66;
          border: 1px solid #092a66 !important;
        }
        @media screen and (max-width: 992px) {
          .btn-article {
            position: relative;
            bottom: 0px;
          }
        }
        @media screen and (max-width: 600px) {
          .btn-article {
            bottom: 0px;
          }
        }
      `}</style>
    </Row>
  );
};

RASeal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  seal: PropTypes.object,
};
RASeal.defaultProps = {
  seal: "",
};

export default RASeal;
