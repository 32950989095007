import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import PropTypes from "prop-types";

const BackgroundSection = (props) => {
  const { children, imageName, className, style } = props;
  const data = useStaticQuery(graphql`
    query WhyHero {
      allFile(
        filter: { relativePath: {}, relativeDirectory: { eq: "hero-images" } }
      ) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: WEBP
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  `);
  const dataCovert = (imgData) => {
    return convertToBgImage(imgData);
  };

  return (
    <>
      {data.allFile.edges.map(
        ({ node }, idx) =>
          node.base === imageName && (
            <BackgroundImage
              Tag="section"
              key={idx.toString()}
              className={className}
              style={style}
              {...dataCovert(node.childImageSharp?.gatsbyImageData)}
            >
              {children}
            </BackgroundImage>
          )
      )}
    </>
  );
};

BackgroundSection.propTypes = {
  imageName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.string),
  // eslint-disable-next-line react/require-default-props
};
BackgroundSection.defaultProps = {
  imageName: "",
  className: "",
  style: {},
};
export default BackgroundSection;
